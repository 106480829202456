import React, { useEffect, useState } from "react";
import { InviteTable } from "./InviteTable";
import { getAllInvite, sendClientInviteEmail } from "../../api/invite";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const SendInvite = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [invites, setInvites] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsValidEmail(validateEmail(inputEmail));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !name || !isValidEmail) return;

    setLoading(true);
    toast.warning("Sending invite");
    try {
      await sendClientInviteEmail(name, email);
      setEmail("");
      setName("");
      setIsValidEmail(false);
      setErrorMessage(null);
      toast.success("Invite sent Successfully");
      const fetchInvites = getAllInvite();
      if (fetchInvites?.length > 0) {
        setInvites(fetchInvites);
      }
    } catch (error) {
      toast.error(error.response?.data?.error_message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col text-gray-100">
      <div className="text-gray-400 p-8 bg-gray-800 rounded-lg">
        {errorMessage && <p className="text-red-500">{String(errorMessage)}</p>}
        <form onSubmit={handleSubmit}>
          <div className="w-full space-y-1">
            {/* Name Input */}
            <label htmlFor="name" className="text-gray-300">
              Enter Customer Name
            </label>
            <div className="relative flex items-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 absolute left-4 inset-y-0 my-auto text-gray-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                />
              </svg>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter name"
                value={name}
                onChange={handleNameChange}
                className="focus:outline-none block w-full rounded-md bg-gray-800 border border-gray-600 pl-12 pr-4 h-12 text-gray-300"
              />
            </div>

            {/* Email Input */}
            <label htmlFor="email" className="text-gray-300">
              Enter Customer Email to Send Invite
            </label>
            <div className="relative flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 absolute left-4 inset-y-0 my-auto text-gray-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                />
              </svg>
              <input
                type="text"
                name="email"
                id="email"
                autoComplete="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                className="focus:outline-none block w-full rounded-md bg-gray-800 border border-gray-600 pl-12 pr-4 h-12 text-gray-300"
              />
              <div className="absolute right-1">
                <button
                  type="submit"
                  disabled={!isValidEmail || loading}
                  className={`relative flex h-10 w-20 ${
                    isValidEmail && name
                      ? "bg-[#5D5D5D] hover:bg-[#007AFF]"
                      : "bg-gray-500 cursor-not-allowed"
                  } rounded-md ml-auto items-center justify-center`}
                >
                  {loading ? "Sending..." : "Send"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <InviteTable invites={invites} />
    </div>
  );
};
