import React from "react";
import { TopNav } from "./TopNav";
import { SideNav } from "./SideNav";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Layout = ({ children }) => {
  return (
    <div className="flex flex-col  bg-gray-900">
      <TopNav />
      <ToastContainer />
      <div className=" w-full flex flex-1 justify-start items-start">
        <div className="w-[20%]">
          <SideNav />
        </div>
        <div className="w-[80%] flex justify-center items-center">
          <main className="w-[100%] py-6 flex justify-center  text-gray-100">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};
