import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "./CustomQuillEditor.css";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline", "strike"],
    [{ color: ["#fff"] }, { background: ["#000"] }],
    [{ align: [] }],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "align",
  "color",
  "background",
  "link",
  "image",
];

export const CustomQuillEditor = ({ value, setValue }) => {
  return (
    <div className="bg-gray-800 p-4 rounded-lg">
      <ReactQuill
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        className="bg-gray-700 text-white rounded"
      />
    </div>
  );
};
