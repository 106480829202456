import axios from "axios";
import config from "./config";
const BASE_URL = config.BASE_URL;

export const getAllPredefinedModel = () => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/predefined-model`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })

    .catch((err) => {
      console.log(err);
      return err?.response?.data;
    });
};
export const getPredefinedModelById = (id) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/predefined-model/by-predefined-model-id/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })

    .catch((err) => {
      console.log(err);
      return err?.response?.data;
    });
};
