import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { login } from "../api/user";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Login = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (checked) => {
    setIsChecked(checked);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login({ email: formData?.email, password: formData?.password })
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("userDetails", JSON.stringify(res?.data));
          navigate("/workflows");
        } else {
          toast.error(res?.response?.data?.error_message);
        }
      })
      .catch((err) => {
        toast.error(err?.error_message);
      });
  };

  return (
    <div className="min-h-screen flex">
      {/* Left Section */}
      <div className="w-2/5 bg-gradient-to-br from-gray-800 to-blue-900 flex flex-col justify-center p-12 text-white">
        <div className="text-3xl font-bold mb-4">Normal Logistics</div>
        <p className="text-lg mb-2">
          Supercharge operations with an AI agent that works alongside your team
        </p>
        <p className="text-md">
          Browse the <span className="font-bold">Workflow Library</span> to
          automate tedious, repetitive manual tasks
        </p>
      </div>

      {/* Right Section */}
      <div className="w-3/5 bg-white flex items-center justify-center p-12">
        <div className="w-full max-w-md">
          <div className="text-xl font-bold mb-6">Normal Logistics</div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email or phone number"
                className="bg-input-grey w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
              />
            </div>
            <div className="mb-4 relative">
              <input
                type={isPasswordVisible ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Enter password"
                className="bg-input-grey w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {isPasswordVisible ? (
                  <FaEyeSlash color="#4D4D4D" size={16} />
                ) : (
                  <FaEye color="#4D4D4D" size={16} />
                )}
              </div>
            </div>
            <div className="flex items-center justify-between mb-6">
              <label className="flex items-center">
                <Switch
                  onChange={handleChange}
                  checked={isChecked}
                  onColor="#4ade80"
                  offColor="#e9e5e5"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40}
                />
                <span className="ml-2 text-gray-600">Remember me</span>
              </label>
              <a href="/" className="text-blue-600 text-sm hover:underline">
                Forgot password?
              </a>
            </div>
            {/* Sign */}
            <div className="mb-4">
              <button
                type="submit"
                className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Sign in
              </button>
            </div>
            {/* Break */}
            <div className="w-full h-[1px] bg-[#E5E5E5] mb-4"></div>
            {/* Google */}
            <div className="mb-4">
              <button
                type="button"
                className="w-full flex items-center justify-center bg-[#333333] text-white p-3 rounded-md hover:bg-[#080808] focus:outline-none focus:ring-2 focus:ring-gray-300"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/google-logo.png"
                  alt="Google"
                  className="h-6 w-6 mr-2"
                />
                Or sign in with Google
              </button>
            </div>
            <div className="text-center">
              <span className="text-sm text-gray-600">
                Don’t have an account?{" "}
              </span>
              <Link
                to={"/signup"}
                className="text-blue-600 text-sm hover:underline"
              >
                Sign up now
              </Link>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
