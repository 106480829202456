import React from "react";

export const TopNav = () => {
  return (
    <div className="  w-[100%]   ">
      <div className="sticky top-0 h-16 border-b border-gray-700 bg-gray-900 lg:py-2.5">
        <div className="flex items-center justify-between space-x-4 px-6">
          {" "}
          <div></div>
          <div className="flex space-x-4">
            {/* Chat, Notification Buttons */}
            <button className="h-10 w-10 rounded-xl border bg-gray-700 active:bg-gray-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="m-auto h-5 w-5 text-gray-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                />
              </svg>
            </button>

            <button className="h-10 w-10 rounded-xl border bg-gray-700 active:bg-gray-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="m-auto h-5 w-5 text-gray-300"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
