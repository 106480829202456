import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import axios from "axios";
import { CustomQuillEditor } from "../CustomQuillEditor";
import { getSingleWorkFlow, updateWorkflows } from "../../api/workFlows";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../api/config";
export const UpdateWorkFlow = () => {
  const [workflowCategories, setWorkflowCategories] = useState([]);
  const [predefinedModels, setPredefinedModels] = useState([]);
  const [data, setData] = useState({
    slack_integration: false,
    deployable_on_phone: false,
    deployable_on_web: false,
    mail_integration: false,
    kb_google_drive: false,
    kb_other_docs: false,
    kb_tms_ulala: false,
    kb_tms_speedx: false,
    predefined_model: false,
    title: "",
    description: "",
    feature_actions: "",
    number_of_steps_to_setup_agent: "",
    cap_amount: "",
    human_resolution_time_in_seconds: "",
    predefined_models_id: "",
    workflow_category_id: "",
    pre_defined_system_prompt: "",
  });
  const navigate = useNavigate();
  const params = useParams();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setData({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      toast.warning("Updating Workflow ");
      const workflow = await updateWorkflows(data);
      if (workflow.status === 200) {
        toast.success("Workflow updated successfully");
        setTimeout(() => {
          navigate("/workflows");
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting workflow:", error);
      toast.error("Error while updating");
    }
  };

  useEffect(() => {
    const fetchWorkflowCategory = async () => {
      const token = localStorage.getItem("userDetails");
      const parse = JSON.parse(token);
      try {
        const workflow = await axios.get(
          `${config?.BASE_URL}/workflow-category/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + parse.access_token,
            },
          }
        );
        setWorkflowCategories(workflow.data);
        const predefined = await axios.get(
          `${config?.BASE_URL}/predefined-model/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + parse.access_token,
            },
          }
        );
        setPredefinedModels(predefined.data);
      } catch (error) {
        console.error("Error fetching workflow categories:", error);
      }
    };

    fetchWorkflowCategory();
  }, []);

  const fetchWorkflow = async (id) => {
    const workflowData = await getSingleWorkFlow(id);
    if (workflowData) {
      setData(workflowData);
    }
  };

  useEffect(() => {
    const id = params.id;
    if (id) {
      fetchWorkflow(id);
    }
  }, [params.id]);

  return (
    <div className="min-h-screen bg-black flex items-center justify-center">
      <ToastContainer />
      <form
        className="w-full space-y-4 p-8 bg-gray-800 rounded-lg shadow-md"
        onSubmit={handleSubmit}
      >
        <h2 className="text-white text-2xl mb-6">Submit Form</h2>

        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Title <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="title"
            className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            value={data.title}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Description
          </label>
          <textarea
            name="description"
            className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            value={data.description}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Feature Action
          </label>
          <div className="mb-4">
            <CustomQuillEditor
              value={data.feature_actions}
              setValue={(value) =>
                setData((prevData) => ({ ...prevData, feature_actions: value }))
              }
            />
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            System Prompt
          </label>
          <textarea
            name="pre_defined_system_prompt"
            className="text-white bg-transparent border"
            rows={5}
            cols={100}
            value={data.pre_defined_system_prompt}
            onChange={handleInputChange}
          ></textarea>
        </div>

        <label className="inline-flex items-center text-white">
          <input
            type="checkbox"
            name="predefined_model"
            checked={data.predefined_model}
            onChange={handleInputChange}
            className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
          />
          <span className="ml-2">Predefined Model</span>
        </label>
        {data.predefined_model && (
          <div className="mb-4 w-full flex flex-col">
            <label htmlFor="predefined_model" className="w-full">
              Predefined Model
            </label>
            <select
              name="predefined_models_id"
              id="predefined_model_id"
              className="input input-primary bg-gray-700 p-2  rounded-md"
              value={data.predefined_models_id}
              onChange={handleInputChange}
            >
              <option selected disabled>
                Select Model
              </option>
              {predefinedModels.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.model_name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="mb-4 w-full flex flex-col">
          <label htmlFor="workflow_category" className="w-full">
            Work Flow Category
          </label>
          <select
            name="workflow_category_id"
            id="workflow_category_id"
            value={data.workflow_category_id}
            className="input input-primary bg-gray-700 p-2  rounded-md"
            onChange={handleInputChange}
          >
            <option selected disabled>
              Select Category
            </option>
            {workflowCategories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.category_name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Human Resolution Time <sup>in sec</sup>{" "}
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="human_resolution_time_in_seconds"
            className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            value={data.human_resolution_time_in_seconds}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Cap Amount <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="cap_amount"
            className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            value={data.cap_amount}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Number Of Steps <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="number_of_steps_to_setup_agent"
            min={1}
            className="w-full px-3 py-2 bg-gray-700 text-white rounded"
            value={data.number_of_steps_to_setup_agent}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">
            Additional Features
          </label>
          <div className="flex flex-col gap-2">
            {[
              { label: "Slack Integration", name: "slack_integration" },
              { label: "Deploy on Phone", name: "deployable_on_phone" },
              { label: "Deploy on Web", name: "deployable_on_web" },
              { label: "Email integration", name: "mail_integration" },
              { label: "Google Drive", name: "kb_google_drive" },
              { label: "Other Docs", name: "kb_other_docs" },
              { label: "TMS Ulala", name: "kb_tms_ulala" },
              { label: "TMS Speedx", name: "kb_tms_speedx" },
            ].map((feature) => (
              <label
                key={feature.name}
                className="inline-flex items-center text-white"
              >
                <input
                  type="checkbox"
                  name={feature.name}
                  checked={data[feature.name]}
                  onChange={handleInputChange}
                  className="form-checkbox h-4 w-4 text-gray-600 bg-gray-700"
                />
                <span className="ml-2">{feature.label}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
