import React, { useEffect, useState } from "react";
import { deleteWorkFlow, getAllWorkflows } from "../api/workFlows";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Workflows = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const getWorkFlows = async () => {
    const data = await getAllWorkflows();
    setData(data);
  };

  useEffect(() => {
    getWorkFlows();
  }, []);
  const dellWorkflow = async (id) => {
    try {
      toast.warning("deleting Workflow");
      const isDeleted = await deleteWorkFlow(id);
      if (isDeleted.message === "Success") {
        toast.success("Workflow deleted successfully");
      }
      getWorkFlows();
    } catch (error) {
      toast.error("Error deleting workflow", error);
    }
  };
  const editWorkFlow = (id) => {
    navigate(`/workflows/update-workflow/${id}`);
  };
  return (
    <div className="space-y-4">
      <ToastContainer />
      <div className="flex justify-between items-center">
        <h2 className="text-black dark:text-white font-extrabold text-start mb-4 mt-4">
          WorkFlows
        </h2>
        <Link
          className="btn bg-none px-4 py-2 border rounded-md"
          to={"add-workflow"}
        >
          +Add WorkFlow
        </Link>
      </div>
      <div className="flex flex-col justify-start items-center flex-wrap gap-4">
        {data?.map((item) => (
          <div
            key={item.id}
            className="bg-white w-full dark:bg-gray-800 shadow-md rounded-lg p-4 border border-gray-300"
          >
            <div className="flex flex-col justify-between items-start ">
              <div className="">
                <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100 mb-2">
                  {item?.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300 mb-2">
                  {item?.description}
                </p>
                <p
                  className="text-gray-600 dark:text-gray-400 text-sm mb-2"
                  dangerouslySetInnerHTML={{
                    __html: item?.feature_actions || "",
                  }}
                ></p>

                <p className="text-gray-600 dark:text-gray-400 text-sm mb-2">
                  System Prompt:
                  <span className="font-light text-white pl-1">
                    {item?.pre_defined_system_prompt?.length > 12
                      ? `${item.pre_defined_system_prompt.slice(0, 12)}...`
                      : item.pre_defined_system_prompt}
                  </span>
                </p>
                <p className="text-gray-600 dark:text-gray-400 text-sm mb-2">
                  Steps:{" "}
                  <span className="font-light text-white pl-1">
                    {item?.number_of_steps_to_setup_agent}
                  </span>
                </p>
                <p className="text-gray-600 dark:text-gray-400 text-sm mb-2">
                  Human resolution:{" "}
                  <span className="font-light text-white pl-1">
                    {item?.human_resolution_time_in_seconds}
                  </span>
                </p>
                <p className="text-gray-600 dark:text-gray-400 text-sm mb-2">
                  Cap Amount:
                  <span className="font-light text-white pl-1">
                    {item?.cap_amount}
                  </span>
                </p>
              </div>
              <div className=" w-full flex justify-end items-center mt-4 sm:mt-0  space-x-2">
                <button
                  className="text-blue-600 hover:text-blue-800"
                  onClick={() => editWorkFlow(item.id)}
                >
                  Edit
                </button>
                <button
                  className="text-red-600 hover:text-red-800"
                  onClick={() => dellWorkflow(item?.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
