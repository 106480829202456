import { Route, Routes } from "react-router-dom";
import "./App.css";
import { AddWorkFlow, Layout, SendInvite, UpdateWorkFlow } from "./components";
import { Login } from "./pages/Login";
import PrivateRoute from "./components/layout/PrivateRoute";
import { Workflows } from "./pages";

function App() {
  return (
    <Routes>
      <Route path="/workflows/create" element={<AddWorkFlow />} />
      <Route path="/" element={<Login />} />
      <Route
        path="/workflows"
        element={
          <PrivateRoute>
            <Layout>
              <Workflows />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/workflows/update-workflow/:id"
        element={
          <PrivateRoute>
            <Layout>
              <UpdateWorkFlow />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/workflows/add-workflow"
        element={
          <PrivateRoute>
            <Layout>
              <AddWorkFlow />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/invitations"
        element={
          <PrivateRoute>
            <Layout>
              <SendInvite />
            </Layout>
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
