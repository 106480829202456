import axios from "axios";
import config from "./config"; // Assuming config.js exports BASE_URL

const BASE_URL = config.BASE_URL;

export const sendClientInviteEmail = async (name, email) => {
  try {
    const token = localStorage.getItem("userDetails");
    const parse = JSON.parse(token);
    return axios
      .post(
        `${BASE_URL}/admin/generate-code-and-send-email`,
        {
          name,
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parse.access_token}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err?.response?.data;
      });
  } catch (error) {
    console.error("Failed to send invite:", error);
    throw error;
  }
};

// get all invite
export const getAllInvite = async () => {
  try {
    const token = localStorage.getItem("userDetails");
    const parse = JSON.parse(token);

    return axios
      .get(`${BASE_URL}/admin/get-all-client-invites`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${parse.access_token}`,
        },
      })
      .then((res) => {
        return res;
      })

      .catch((err) => {
        console.log(err);
        return err?.response?.data;
      });
  } catch (error) {
    console.error("Failed to fetch invites:", error);
    throw error;
  }
};
